import React, { useEffect, useReducer, useState } from "react";
import axios from "../appConfig/httpHelper";
import { useSelector } from "react-redux";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { innerTableActionBtnDesign } from "../components/styles/innerTableActions";
import ActionButtons from "../components/actionsButtons/Index";
import { DataTable } from "../components/table/Index";
import { AddNewEntryMonthlyPlan } from "./forms/AddNewMonthlyPlan";
import { Modal, Button } from "antd";
import { toast } from "react-toastify";

const MonthlyPlan = () => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  const user = useSelector((state) => state.user.user);
  const day = new Date();

  const [actions, setActions] = useReducer(
    (state, diff) => ({ ...state, ...diff }),
    {
      drawer: false,
      loading: false,
      pagination: 15,
      trash: false,
      newPlan: false,
      loadingAllPlans: false,
      downloadAllPlans: false,
    }
  );

  const { drawer, newPlan, loading, pagination } = actions;

  const [value, setValue] = useReducer(
    (state, diff) => ({ ...state, ...diff }),
    { plans: [], allPlans: [], drawerValue: {} }
  );

  const { plans, allPlans, drawerValue } = value;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const requestsCaller = () => {
    setActions({ loading: true });

    if (!token) {
      toast.error("User is not authenticated");
      setActions({ loading: false });
      return;
    }

    axios
      .get(`/monthly-plan/consumption/get-all/user-id/${user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setValue({
          plans: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          toast.error("Unauthorized access - Please log in again");
        } else {
          toast.error("Failed to fetch plans");
        }
      })
      .finally(() => setActions({ loading: false }));
  };

  const DeleteItem = (planId) => {
    console.log(planId);
    console.log(token);

    if (!token) {
      toast.error("User is not authenticated");
      return;
    }

    axios
      .delete(`/monthly-plan/consumption/delete/plan-id/${planId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast.success(res.data.message);
        requestsCaller();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Product Deletion Failed");
      });
  };

  useEffect(() => requestsCaller(), []);

  const addNewPlan = () => {
    let matchingDate = false;

    if (!matchingDate) {
      setIsModalVisible(true);
    } else {
      toast.error(
        "New Monthly Plan Cannot be added as a plan for this month has already been added. Try Editing this month's plan instead."
      );
    }
  };

  const backAddNewPlan = () => {
    setIsModalVisible(false);
  };

  const onCloseDrawer = () => {
    setActions({ drawer: false });
  };

  const columns = [
    {
      key: "totalPlan",
      title: "Total Plan",
      render: (data) => data.total,
    },
    {
      key: "date",
      title: "Date",
      render: (data) => data.date,
    },
    {
      key: "month",
      title: "Month",
      render: (data) => data.month,
    },
    {
      key: "year",
      title: "Year",
      render: (data) => data.year,
    },
    {
      key: "actions",
      title: "Actions",
      render: (record) => <ColumnActions record={record} />,
    },
  ];

  const ColumnActions = (props) => {
    return (
      <div className="flex justify-around">
        <Button
          type="secondary"
          onClick={() => {
            setActions({ drawer: true });
            setValue({ drawerValue: props?.record });
          }}
        >
          <EyeOutlined title="View" style={innerTableActionBtnDesign} />
        </Button>

        {!props?.record?.isApproved && (
          <Button
            type="primary"
            onClick={() => DeleteItem(props?.record?.monthlyPlanId)}
          >
            <DeleteOutlined title="Ban" style={innerTableActionBtnDesign} />
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <Modal
        title="Add New Monthly Plan"
        visible={isModalVisible}
        onCancel={backAddNewPlan}
        footer={null}
        width={1200}
      >
        <AddNewEntryMonthlyPlan
          back={backAddNewPlan}
          requestsCaller={requestsCaller}
        />
      </Modal>

      <div className="">
        <ActionButtons
          pageTitle={"Monthly Consumption Plan"}
          showTrashButton={false}
          showTrashFunction={""}
          showReFreshButton={true}
          refreshFunction={requestsCaller}
          showExportDataButton={false}
          exportDataFunction={""}
          totalItems={""}
          loadingItems={""}
          downloadItems={""}
          showAddNewButton={true}
          addNewFunction={addNewPlan}
        />
        <div className="border-2 mt-5">
          <DataTable usersData={plans} columns={columns} loading={loading} />
        </div>
        {/* <DrawerComp
          title={"QR Code"}
          visible={drawer}
          onCloseDrawer={onCloseDrawer}
          data={drawerValue}
        /> */}
      </div>
    </>
  );
};

export default MonthlyPlan;
